import React, { useState } from 'react';

export const StateContext = React.createContext();

export function StateProvider({ children }) {
  const [menuExpanded, toggleMenuExpanded] = useState(false);
  const [highlightSubscribe, toggleHighlightSubscribe] = useState(false);

  return (
    <StateContext.Provider value={{
      menuExpanded,
      toggleMenuExpanded,
      highlightSubscribe,
      toggleHighlightSubscribe,
    }}>
      { children }
    </StateContext.Provider>
  );
};
