/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { StateProvider } from './src/providers/state';

import './src/assets/styles/index.scss';

export const wrapRootElement = ({ element }) => {
  return <StateProvider>{element}</StateProvider>;
};
